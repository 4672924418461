import VisibleMixin from '../visible_properties_mixin'
export default {
  mixins: [VisibleMixin],
  inject: ['isEditor'],
  props: {
    value: {
      frozen: true
    },
    size: {
      type: String,
      description: 'Размер'
    },
    align: {
      type: String,
      description: 'Выравнивание'
    },
    margin: {
      type: String,
      description: 'Отступы',
      default: '5px 10px'
    },
    width: {
      type: String,
      description: 'Ширина'
    },
    block: {
      type: Boolean,
      description: 'Во всю строку',
      default: true
    },
    wrapper: {
      type: Boolean,
      description: 'Блок',
      default: false
    },
    isShow: {
      description: 'Скрыть',
      type: Boolean,
      frozen: true
    },
    pluginName: {
      type: String,
      editor: 'Plugin',
      frozen: true
    }
  },
  computed: {
    _isRequired () {
      if (this.isHidden) {
        return false
      }
      return this.required
    }
  },
  mounted () {
    if (this.isShow) {
      this.$set(this.hiddenCondition, 'type', 'always')
    }
    if (this.pluginName && this.editorChart.type !== 'extend_object' && !this.editorChart.plugin) {
      this.$set(this.editorChart, 'plugin', this.pluginName)
    }
  }
}
